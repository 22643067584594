import React, { useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import './Presupuesto.css'; 

const Presupuesto = () => {
  const [rows, setRows] = useState([]);
  const [newRow, setNewRow] = useState({ descripcion: "", cantidad: 1, precio: 0 });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewRow({ ...newRow, [name]: value });
  };

  const handleAddRow = () => {
    setRows([...rows, newRow]);
    setNewRow({ descripcion: "", cantidad: 1, precio: 0 }); // Resetear el formulario
  };

  const calcularTotal = () => {
    return rows.reduce((total, row) => total + parseFloat(row.precio || 0), 0);
  };

  const generarPDF = () => {
    const formElement = document.getElementById('form-content');
    formElement.style.display = 'none'; // Oculta el formulario antes de generar el PDF
    const input = document.getElementById('pdf-content');

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 190;
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      formElement.style.display = 'block'; // Muestra el formulario después de generar el PDF
      pdf.save("presupuesto lubribox.pdf"); // Cambiamos el nombre aquí
    });
  };

  return (
    <div className="container">
      <div id="pdf-content">
        <div className="header">
        <img src={`${process.env.PUBLIC_URL}/lubribox.jpg`} alt="Logo" className="logo" />
        <h1>Presupuesto</h1>
        </div>

        {/* Tabla para mostrar los productos */}
        <table className="excel-table">
          <thead>
            <tr>
              <th>Descripción del Producto</th>
              <th>Cantidad</th>
              <th>Precio</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>{row.descripcion}</td>
                <td>{row.cantidad}</td>
                <td>${parseFloat(row.precio).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Total debajo de la tabla */}
        <div className="total">
          <h3>Total: ${calcularTotal().toFixed(2)}</h3>
        </div>
      </div>

      {/* Formulario para agregar una fila (No se verá en el PDF) */}
      <div id="form-content" className="form">
        <input
          type="text"
          name="descripcion"
          placeholder="Descripción del Producto"
          value={newRow.descripcion}
          onChange={handleInputChange}
        />
        <input
          type="number"
          name="cantidad"
          placeholder="Cantidad"
          value={newRow.cantidad}
          onChange={handleInputChange}
        />
        <input
          type="number"
          name="precio"
          placeholder="Precio"
          value={newRow.precio}
          onChange={handleInputChange}
        />
        <button onClick={handleAddRow} className="add-row-button">Agregar Producto</button>
      </div>

      {/* Botón para generar el PDF */}
      <button onClick={generarPDF} className="pdf-button">Generar PDF</button>
    </div>
  );
};

export default Presupuesto;
