import React from 'react';
import './App.css'; // Si tienes estilos globales para la app
import Presupuesto from './components/Presupuesto'; // Importa el componente Presupuesto

function App() {
  return (
    <div className="App">
      <Presupuesto /> {/* Renderiza tu componente */}
    </div>
  );
}

export default App;
